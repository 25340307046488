export function assert(condition: unknown, message = "assertion failed"): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export function must<T>(input: T, message = "Cannot be null or undefined"): NonNullable<T> {
  assert(input !== null && input !== undefined, message);

  return input as NonNullable<T>;
}
