import React, { ReactNode } from "react";
import { Separator, Button, Icon, Spinner, Stack, ButtonProps, Avatar } from "@chakra-ui/react";
import { BsCalendar, BsPerson, BsHouseDoor, BsBoxArrowRight, BsCurrencyDollar } from "react-icons/bs";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useLocation } from "react-router";
import { useUser } from "../hooks/user";
import { GearIcon } from "./icons";

function UsernameMember() {
  return <span>Member</span>;
}

function Username() {
  const user = useUser();

  return <span>{user ? user.name : "Unknown"}</span>;
}

function NavLink(props: ButtonProps & { icon: ReactNode; expanded?: boolean; active?: boolean }) {
  return (
    <Button
      variant="ghost"
      size="sm"
      fontSize="sm"
      fontWeight="normal"
      width="full"
      justifyContent="flex-start"
      _active={{ bg: "bg.emphasized" }}
      data-active={props.active ? "true" : undefined}
      onClick={props.onClick}
    >
      <Icon boxSize="4">{props.icon}</Icon>
      {props.children}
    </Button>
  );
}

export function Sidebar(): ReactNode {
  const expanded = false;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useUser();

  return (
    <Stack px="2" height="100vh" justifyContent="space-between" alignItems="flex-start" gap="1">
      <Stack>
        <NavLink
          expanded={expanded}
          icon={<BsHouseDoor />}
          active={pathname === "/todo"}
          onClick={() => {
            navigate("/todo");
          }}
        >
          Dashboard
        </NavLink>

        <NavLink
          expanded={expanded}
          icon={<BsCalendar />}
          active={pathname === "/upload"}
          onClick={() => {
            navigate("/upload");
          }}
        >
          Upload
        </NavLink>
        <NavLink
          expanded={expanded}
          icon={<BsPerson />}
          active={pathname === "/gpt"}
          onClick={() => {
            navigate("/gpt");
          }}
        >
          GptTest
        </NavLink>
        <NavLink
          expanded={expanded}
          icon={<BsCurrencyDollar />}
          active={pathname === "/message"}
          onClick={() => {
            navigate("/message");
          }}
        >
          Messages
        </NavLink>
        <NavLink
          expanded={expanded}
          icon={<BsCurrencyDollar />}
          active={pathname === "/feed"}
          onClick={() => {
            navigate("/feed");
          }}
        >
          Feed
        </NavLink>
      </Stack>

      <Stack>
        <Separator display={expanded ? "flex" : "none"} />
        <NavLink
          expanded={expanded}
          icon={
            <Avatar.Root>
              <Avatar.Image src={user?.avatar_url} />
            </Avatar.Root>
          }
        >
          <ErrorBoundary FallbackComponent={UsernameMember}>
            <React.Suspense fallback={<Spinner />}>
              <Username />
            </React.Suspense>
          </ErrorBoundary>
        </NavLink>
        <NavLink
          expanded={expanded}
          icon={<GearIcon />}
          active={pathname.startsWith("/settings")}
          onClick={() => {
            navigate("/settings/");
          }}
        >
          Settings
        </NavLink>
        <NavLink
          expanded={expanded}
          icon={<BsBoxArrowRight />}
          onClick={() => {
            navigate("/auth/logout");
          }}
        >
          Logout
        </NavLink>
      </Stack>
    </Stack>
  );
}
