import React, { ReactNode } from "react";
import { IconProps, Icon } from "@chakra-ui/react";
import { SiGithub, SiGoogle } from "@icons-pack/react-simple-icons";
import { BsGear, BsBell, BsKey, BsPlus, BsThreeDotsVertical } from "react-icons/bs";
import {
  FiEyeOff,
  FiEye,
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiChevronsDown,
  FiMenu,
  FiEdit,
  FiDelete,
} from "react-icons/fi";

export function GoogleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <SiGoogle />
    </Icon>
  );
}

export function GithubIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <SiGithub />
    </Icon>
  );
}

export function GearIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <BsGear />
    </Icon>
  );
}

export function BellIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <BsBell />
    </Icon>
  );
}

export function KeyIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <BsKey />
    </Icon>
  );
}

export function PlusIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <BsPlus />
    </Icon>
  );
}

export function EyeIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiEye />
    </Icon>
  );
}

export function EyeOffIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiEyeOff />
    </Icon>
  );
}

export function HomeIcon(props: IconProps): ReactNode {
  return (
    <Icon {...props}>
      <FiHome />
    </Icon>
  );
}

export function TrendingIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiTrendingUp />
    </Icon>
  );
}

export function CompassIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiCompass />
    </Icon>
  );
}

export function StarIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiStar />
    </Icon>
  );
}

export function SettingsIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiSettings />
    </Icon>
  );
}

export function ChevronDownIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiChevronsDown />
    </Icon>
  );
}

export function MenuIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiMenu />
    </Icon>
  );
}

export function EditIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiEdit />
    </Icon>
  );
}

export function DeleteIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <FiDelete />
    </Icon>
  );
}

export function KebabIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <BsThreeDotsVertical />
    </Icon>
  );
}
