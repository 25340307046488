import * as Sentry from "@sentry/react";
// import { z } from "zod";
import { DetailedError, HttpError } from "../hooks/rpc/client";
// import { FetchError } from "./utils";
import { toaster } from "@/components/ui/toaster";

export interface ErrorHandlers {
  invalidArgument?(fields: Record<string, string>): void;
}

export function onErrorShared(handlers?: ErrorHandlers) {
  return (error: unknown) => {
    if (error instanceof DetailedError) {
      if (error.code === "INVALID_ARGUMENT") {
        let entries: Record<string, string> = {};

        if (error.details) {
          entries = Object.fromEntries(error?.details.map(({ field, description }) => [field, description]));
        }

        handlers?.invalidArgument?.(entries);
      } else {
        toaster.create({
          type: "error",
          title: "Unexpected server response: " + error.code,
        });
      }
    }
    if (error instanceof HttpError) {
      // eslint-disable-next-line no-console
      console.log("HTTP ERROR ", error.code);
      if (error.code >= 500) {
        toaster.create({
          type: "error",
          title: "Unexpected server response",
        });

        Sentry.captureMessage("Network error", {
          level: "info",
          tags: { network_error: error.message },
        });
        return;
      }
    }
  };
}

/*
export function xonErrorShared(error: unknown) {
  // eslint-disable-next-line no-debugger
  debugger;
  if (error instanceof FetchError) {
    if (isGrpcError(error)) {
      const { code } = error.body;
      if (code === "invalid_argument") {
        // Should be handled by forms
        return;
      }

      if (code === "internal") {
        toaster.create({
          title: "Internal error",
          type: "error",
        });

        return;
      }
      if (code === "unauthenticated") {
        // TODO handle
        return;
      }

      // Log it
    }
  }
  if (error instanceof TypeError) {
    // Network issue
    toaster.create({
      type: "error",
      title: "Networking error encountered",
    });

    Sentry.captureMessage("Network error", {
      level: "info",
      tags: { network_error: error.message },
    });
    return;
  }

  // Log this for future understanding
  Sentry.captureException(error);

  // // This is react-query throwing a CancelError
  // const isCancelledError = error && Object.hasOwn(error, "silent");
  // if (isCancelledError) {
  //   return;
  // }
  // eslint-disable-next-line no-console
  console.error(error);

  toaster.create({
    type: "error",
    title: "An unexpected error occured",
  });
}
  */
