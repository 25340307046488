import { Box, Flex } from "@chakra-ui/react";
import { EditorContent, Editor } from "@tiptap/react";
import { Toolbar } from "./Toolbar";

export type Menu = null | "fixed"; // | "bubble" | "floating";

export interface EditorProps {
  editor: Editor | null;
  menu?: Menu;
}

export function RichTextEditor({ editor, menu = "fixed" }: EditorProps) {
  if (!editor) {
    return null;
  }

  return (
    <Flex direction="column" maxH="md" borderRadius="md">
      {menu === "fixed" ? <Toolbar editor={editor} /> : null}
      <Box p=".25rem 0.5rem" flex="1 1 auto" overflowX="hidden" overflowY="auto">
        <EditorContent width="100%" height="100%" editor={editor} />
      </Box>
    </Flex>
  );
}
