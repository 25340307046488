import { useEffect } from "react";
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { useAuth } from "../authStore";
import { newFetchClient } from "../../rpc/utils";
import { useWebsocketUpdates } from "../../rpc/websocket";

const FEED_KEY = "feeds";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeedPreviewRequestSchema = z.object({
  url: z.string(),
});
const FeedPreviewResponseSchema = z.object({
  id: z.string(),
});

type FeedPreviewRequest = z.infer<typeof FeedPreviewRequestSchema>;
type FeedPreviewResponse = z.infer<typeof FeedPreviewResponseSchema>;

export function useFeedApi() {
  const { token } = useAuth();
  const client = newFetchClient({ token });

  return {
    useFeedFetch(
      params?: Pick<
        UseMutationOptions<FeedPreviewResponse, Error, FeedPreviewRequest>,
        "onError" | "onSuccess" | "onSettled"
      >,
    ) {
      return useMutation({
        async mutationFn(data: FeedPreviewRequest) {
          const response = await client.POST("/v1/feed/preview", {
            url: data.url,
          });

          return FeedPreviewResponseSchema.parse(response);
        },
        ...params,
      });
    },

    useFetchRecord(id: string): FeedPreview | null {
      const query = useQuery<FeedPreview>({ queryKey: [FEED_KEY, id], enabled: false });

      return query.data ?? null;
    },
  };
}

const FeedPreviewSchema = z.object({
  id: z.string(),
  url: z.string(),
  title: z.string(),
  success: z.boolean(),
});
type FeedPreview = z.infer<typeof FeedPreviewSchema>;

const FeedPreviewEventResponseSchema = z.object({
  object_id: z.string(),
  action: z.enum(["preview"]),
  topic: z.literal("feed"),
  body: z.nullable(FeedPreviewSchema),
});
type FeedPreviewEventResponse = z.infer<typeof FeedPreviewEventResponseSchema>;

export function useFeedListener() {
  const queryClient = useQueryClient();
  const { addListener } = useWebsocketUpdates();

  useEffect(
    () =>
      addListener("feed", (event: FeedPreviewEventResponse) => {
        const data = FeedPreviewEventResponseSchema.parse(event);

        if (data.body) {
          queryClient.setQueryData<FeedPreview>([FEED_KEY, data.object_id], data.body);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
