import { Link as RouterLink } from "react-router-dom";
import { Link, Text } from "@chakra-ui/react";

export function RegisterCta({ text = "" }: { text?: string }) {
  return (
    <Text fontSize="sm">
      {text}{" "}
      <Link asChild colorPalette="blue">
        <RouterLink to="/auth/register">Register</RouterLink>
      </Link>
    </Text>
  );
}

export function LoginCta({ text = "" }: { text?: string }) {
  return (
    <Text fontSize="sm">
      {text}{" "}
      <Link asChild colorPalette="blue">
        <RouterLink to="/auth/login">Sign-in</RouterLink>
      </Link>
    </Text>
  );
}

export function ForgotCta({ text = "Forgot password?", email }: { text?: string; email?: string }) {
  return (
    <Text fontSize="sm">
      <Link asChild colorPalette="blue">
        <RouterLink
          to={{
            pathname: "/auth/recover/send",
            search: email ? "?email=" + encodeURIComponent(email) : undefined,
          }}
        >
          {" "}
          {text}
        </RouterLink>
      </Link>
    </Text>
  );
}
