// https://dribbble.com/shots/15186840-Setting-page-example

import React, { useState } from "react";
import { Text, Heading, Field, Button, Flex, Box } from "@chakra-ui/react";
import { toaster } from "@/components/ui/toaster";
import { useForm } from "react-hook-form";
import { PasswordInput } from "../../components/PasswordInput";
import { useUserUpdate } from "../../hooks/user";

const INPUT_STYLE = {
  bg: "white",
  borderColor: "gray.400",
};

interface FormFields {
  password: string;
  passwordNew: string;
  passwordAgain: string;
}

export function SecuritySettings() {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {},
  });
  const updateUser = useUserUpdate({
    onSettled() {
      setSubmitting(false);
    },
    // onErrorField(serror: Record<string, string[]>) {
    //   const fields: (keyof FormFields)[] = ["password", "passwordAgain", "passwordNew"];
    //   fields.forEach((key: keyof FormFields) => {
    //     const message = serror[key]?.[0];
    //     if (message) {
    //       setError(key, { message });
    //     }
    //   });
    // },
    onSuccess() {
      toaster.create({
        description: "Profile Updated",
        type: "success",
      });
    },
  });

  function onSubmit(data: FormFields) {
    if (data.passwordAgain !== data.passwordNew) {
      setError("passwordNew", { message: "Password doesn't match retryped" });
      return;
    }
    setSubmitting(true);
    updateUser.mutate({ body: data });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="100%" p="5">
        <Flex justify="center">
          <Box w="80%">
            <Heading fontSize="3xl">Security</Heading>
            <Box pt="5">
              <Heading fontSize="lg">Profile</Heading>
              <Text>Please help keep your account secure</Text>
            </Box>

            <Box pt="5">
              <Field.Root invalid={!!errors.password}>
                <Field.Label>Old Password</Field.Label>
                <Flex>
                  <PasswordInput
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Old password is required",
                      },
                    })}
                    {...INPUT_STYLE}
                  />
                </Flex>
                <Field.ErrorText>{errors.password?.message}</Field.ErrorText>
              </Field.Root>
            </Box>

            <Box pt="5">
              <Field.Root invalid={!!errors.passwordNew}>
                <Field.Label>New Password</Field.Label>
                <Flex>
                  <PasswordInput
                    {...register("passwordNew", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Password must have 8 characters",
                      },
                    })}
                    {...INPUT_STYLE}
                  />
                </Flex>
                <Field.ErrorText>{errors.passwordNew?.message}</Field.ErrorText>
              </Field.Root>
            </Box>

            <Box pt="5">
              <Field.Root invalid={!!errors.passwordAgain}>
                <Field.Label>Repeat New Password</Field.Label>
                <Flex>
                  <PasswordInput
                    {...register("passwordAgain", {
                      required: {
                        value: true,
                        message: "Retyping password is required",
                      },
                      minLength: {
                        value: 8,
                        message: "Password must have 8 characters",
                      },
                    })}
                    {...INPUT_STYLE}
                  />
                </Flex>
                <Field.ErrorText>{errors.passwordAgain?.message}</Field.ErrorText>
              </Field.Root>
            </Box>

            <Flex pt="5" justify="right">
              <Button mt={4} colorScheme="teal" loading={isSubmitting} type="submit" onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
