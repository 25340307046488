import { ReactNode } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { RandomImage } from "../../components/RandomImage";
import { Outlet } from "react-router";

export function AuthLayout(): ReactNode {
  return (
    <Stack minH="100vh" direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align="center" justify="center">
        <Outlet />
      </Flex>
      <Flex flex={1}>
        <RandomImage width="100%" bgRepeat="no-repeat" bgPos="center" bgSize="cover" />
      </Flex>
    </Stack>
  );
}
