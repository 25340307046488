import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { Tooltip } from "@/components/ui/tooltip";

interface ToolbarButtonProps extends Omit<IconButtonProps, "aria-label"> {
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
}

export default function ToolbarButton({ label, isActive, icon, ...rest }: ToolbarButtonProps) {
  return (
    <Tooltip content={label}>
      <IconButton size="sm" variant={isActive ? "ghost" : "surface"} colorScheme="gray" aria-label={label} {...rest}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}
