import { Input, IconButton, InputProps } from "@chakra-ui/react";
import { InputGroup } from "@/components/ui/input-group";
import React, { useState } from "react";
import { EyeIcon, EyeOffIcon } from "./icons";

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  function onPasswordVisible() {
    setPasswordVisible(!passwordVisible);
  }

  const { width, ...rest } = props;

  return (
    <InputGroup
      width={width}
      endElement={
        <IconButton aria-label="view password" size="sm" onClick={() => onPasswordVisible()} variant="ghost">
          {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
        </IconButton>
      }
    >
      <Input ref={ref} type={passwordVisible ? "text" : "password"} {...rest} />
    </InputGroup>
  );
});

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
