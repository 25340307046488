// https://dribbble.com/shots/15186840-Setting-page-example

import React, { useState } from "react";
import { Text, Heading, Field, Input, Button, Flex, Box, Grid, Textarea } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toaster } from "@/components/ui/toaster";
import { useUserUpdate, useUser } from "../../hooks/user";
import { onErrorShared } from "../../rpc/error";

const INPUT_STYLE = {
  bg: "white",
  borderColor: "gray.400",
};

interface FormFields {
  name: string;
  about: string;
  email: string;
  country: string;
  language: string;
  phone: string;
}

export function ProfileSettings() {
  const user = useUser();
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      name: user.name ?? "",
      email: user.email ?? "",
    },
  });
  const updateUser = useUserUpdate({
    onSettled() {
      setSubmitting(false);
    },
    onError: onErrorShared({
      invalidArgument(fields) {
        const formFields = ["email", "name", "email", "about", "country", "language", "phone"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
    onSuccess() {
      toaster.create({
        title: "Profile Updated",
        type: "success",
      });
    },
  });

  function onSubmit(data: FormFields) {
    setSubmitting(true);
    updateUser.mutate({ body: data });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w="100%" p="5">
        <Flex justify="center">
          <Box w="80%">
            <Heading fontSize="3xl">Account</Heading>
            <Box pt="5">
              <Heading fontSize="lg">Profile</Heading>
              <Text>This information will be displayed publically so be careful what you share</Text>
            </Box>

            <Box pt="5">
              <Field.Root invalid={!!errors.name}>
                <Field.Label>Name</Field.Label>
                <Flex>
                  <Input
                    type="text"
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Name must be at least 2 characters",
                      },
                    })}
                    {...INPUT_STYLE}
                  />
                </Flex>
                <Field.ErrorText>{errors.name?.message}</Field.ErrorText>
              </Field.Root>
            </Box>

            <Box pt="5">
              <Field.Root invalid={!!errors.about}>
                <Field.Label>About</Field.Label>
                <Flex>
                  <Textarea {...register("about")} {...INPUT_STYLE} />
                </Flex>
                <Field.ErrorText>{errors.about?.message}</Field.ErrorText>
              </Field.Root>
            </Box>

            <Box pt="5">
              <Heading fontSize="lg">Personal Information</Heading>
              <Text>This information will be displayed publically so be careful what you share</Text>
            </Box>

            <Grid pt="5" templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap="6">
              <Field.Root invalid={!!errors.email}>
                <Field.Label>Email address</Field.Label>
                <Flex>
                  <Input
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                    {...INPUT_STYLE}
                  />
                </Flex>
                <Field.ErrorText>{errors.email?.message}</Field.ErrorText>
              </Field.Root>

              <Field.Root invalid={!!errors.phone}>
                <Field.Label>Phone number</Field.Label>
                <Flex>
                  <Input type="text" {...register("phone")} {...INPUT_STYLE} />
                </Flex>
                <Field.ErrorText>{errors.phone?.message}</Field.ErrorText>
              </Field.Root>

              <Field.Root invalid={!!errors.country}>
                <Field.Label>Country</Field.Label>
                <Flex>
                  <Input type="text" {...register("country")} {...INPUT_STYLE} />
                </Flex>
                <Field.ErrorText>{errors.country?.message}</Field.ErrorText>
              </Field.Root>

              <Field.Root invalid={!!errors.language}>
                <Field.Label>Language</Field.Label>
                <Flex>
                  <Input type="text" {...register("language")} {...INPUT_STYLE} />
                </Flex>
                <Field.ErrorText>{errors.language?.message}</Field.ErrorText>
              </Field.Root>
            </Grid>

            <Flex pt="5" justify="right">
              <Button mt={4} colorScheme="teal" loading={isSubmitting} type="submit" onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
