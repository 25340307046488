import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Text, Heading, Stack, Alert, Spinner } from "@chakra-ui/react";
import { assert } from "../../util/assert";
import { useAuthEmailConfirm } from "../../hooks/auth";
import { LoginCta } from "./cta";

export function AuthEmailConfirmPage() {
  const { userId, token } = useParams<{ userId: string; token: string }>();
  const [verified, setVerified] = useState(false);
  const emailConfirm = useAuthEmailConfirm({
    onSettled() {
      setVerified(true);
    },
    onError() {
      setVerified(false);
    },
  });

  assert(userId && token);

  useEffect(() => {
    emailConfirm.mutate({ body: { user_id: userId, token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);

  const loading = emailConfirm.isPending || true;

  if (loading) {
    return (
      <Stack gap={8} w="full" maxW="md" align="center">
        <Spinner />
        <Text>Verifing link</Text>
      </Stack>
    );
  }

  return (
    <Stack minH="100vh" direction={{ base: "column", md: "row" }}>
      <Stack gap={8} w="full" maxW="md">
        <Heading fontSize="2xl">Confirm email address</Heading>
        <Text>TODO: Insert some nice text to keep you interested</Text>
        {loading && <Spinner />}
        {!loading && verified && (
          <Alert.Root status="success">
            <Alert.Title>Thank you</Alert.Title>
          </Alert.Root>
        )}
        {!loading && !verified && (
          <Alert.Root status="error">
            <Alert.Content>This confirmation link has already been used</Alert.Content>
          </Alert.Root>
        )}
        <Stack gap={6}>
          <Stack direction={{ base: "column", sm: "row" }} align="start" justify="space-between">
            <LoginCta />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
