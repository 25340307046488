import { z } from "zod";
import { useMemo } from "react";
import createClient, { FetchResponse, Middleware, ParamsOption, RequestBodyOption } from "openapi-fetch";
import { UseMutationOptions } from "@tanstack/react-query";
import { useAuth } from "../authStore";

export type QueryData<T extends Record<string | number, unknown>> = Exclude<
  FetchResponse<T, "json", "application/json">["data"],
  undefined
>;

export type MutationOptions<
  T extends object,
  TData = Exclude<FetchResponse<T, "json", "application/json">["data"], undefined>,
  TError = Exclude<FetchResponse<T, "json", "application/json">["error"], undefined>,
  TVariables = RequestBodyOption<T> & ParamsOption<T>,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext>;

export class HttpError extends Error {
  code: number;

  constructor(code: number, message: string) {
    super(message);

    this.code = code;
  }
}

const DetailedErrorSchema = z.object({
  code: z.union([
    z.literal("OK"),
    z.literal("CANCELLED"),
    z.literal("UNKNOWN"),
    z.literal("INVALID_ARGUMENT"),
    // z.literal("INVALID_ARGUMENT"),
    z.literal("DEADLINE_EXCEEDED"),
    z.literal("NOT_FOUND"),
    z.literal("ALREADY_EXISTS"),
    z.literal("PERMISSION_DENIED"),
    z.literal("UNAUTHENTICATED"),
    z.literal("RESOURCE_EXHAUSTED"),
    z.literal("FAILED_PRECONDITION"),
    z.literal("ABORTED"),
    z.literal("OUT_OF_RANGE"),
    z.literal("UNIMPLEMENTED"),
    z.literal("INTERNAL"),
    z.literal("UNAVAILABLE"),
    z.literal("DATA_LOSS"),
  ]),
  message: z.optional(z.string()),
  details: z.optional(
    z.array(
      z.object({
        field: z.string(),
        description: z.string(),
      }),
    ),
  ),
});

export const API_URL = import.meta.env.VITE_API_URL ?? "NOIT_SET";

type DetailedErrorType = z.infer<typeof DetailedErrorSchema>;

export class DetailedError extends Error {
  statusCode: number;
  code: DetailedErrorType["code"];
  details: DetailedErrorType["details"];

  constructor(statusCode: number, detailed: DetailedErrorType) {
    super(detailed.message);

    this.statusCode = statusCode;
    this.code = detailed.code;
    this.details = detailed.details;
  }
}

function createError(statusCode: number, body: unknown) {
  const result = DetailedErrorSchema.safeParse(body);

  if (!result.success) {
    return new HttpError(statusCode, `HTTP Error status=${statusCode} body=${JSON.stringify(body)}`);
  }

  return new DetailedError(statusCode, result.data);
}

//
//

function throwOnError(setToken?: (token: string | null) => void): Middleware {
  return {
    async onResponse(res) {
      if (res.response.status >= 400) {
        if (res.response.status === 401 || res.response.status === 403) {
          setToken?.(null);
        }

        const body = res.response.headers.get("content-type")?.includes("json")
          ? await res.response.clone().json()
          : await res.response.clone().text();
        throw createError(res.response.status, body);
      }

      return undefined;
    },
  };
}

export function useFetchClientAuth<Paths extends object>() {
  const { token, setToken } = useAuth();

  return useMemo(() => {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const client = createClient<Paths>({
      baseUrl: API_URL,
      headers,
    });

    client.use(throwOnError(setToken));

    return client;
  }, [token, setToken]);
}

export function useFetchClient<Paths extends object>() {
  return useMemo(() => {
    const client = createClient<Paths>({ baseUrl: API_URL });

    client.use(throwOnError());

    return client;
  }, []);
}

// Decode error
