import { Box, BoxProps } from "@chakra-ui/react";

export function RandomImage(props: BoxProps) {
  return (
    <Box
      bgImage="url(https://picsum.photos/700/1000)"
      width="100%"
      bgRepeat="no-repeat"
      bgPos="center"
      bgSize="cover"
      {...props}
    />
  );
}
