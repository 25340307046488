import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Text, Button, Field, Heading, Input, Stack, Alert } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAuthRecoverSend } from "../../hooks/auth";
import { onErrorShared } from "../../rpc/error";
import { LoginCta } from "./cta";

interface FormFields {
  email: string;
}

export function AuthRecoverSendPage() {
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<FormFields>();
  const [completed, setCompleted] = useState(false);
  const recoverSend = useAuthRecoverSend({
    onSettled() {
      setCompleted(true);
    },
    onError: onErrorShared({
      invalidArgument(fields) {
        const formFields = ["email"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      },
    }),
  });

  function onSubmit(data: { email: string }) {
    recoverSend.mutate({ body: data });
  }

  useEffect(() => {
    const email = searchParams.get("email") || "";
    if (email) {
      setValue("email", email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (completed) {
    return (
      <Alert.Root status="success">
        <Alert.Indicator />
        <Alert.Title>Sent</Alert.Title>
        <Alert.Description>Instructions have been sent, please check your email.</Alert.Description>
      </Alert.Root>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={8} w="full" maxW="md">
        <Heading fontSize="2xl">Forgot your password?</Heading>
        <Text>
          Entere the email address you signed up with, and we will send instructions on how to reset your password
        </Text>
        <Field.Root id="email" invalid={!!errors.email}>
          <Field.Label>Email address</Field.Label>
          <Input
            autoFocus
            type="email"
            {...register("email", {
              required: {
                value: true,
                message: "Email address is required",
              },
            })}
          />
          <Field.ErrorText>{errors.email?.message}</Field.ErrorText>
        </Field.Root>
        <Stack gap={6}>
          <Button loading={recoverSend.isPending} disabled={completed} type="submit" colorScheme="blue" variant="solid">
            Reset your password
          </Button>
          <Stack direction={{ base: "column", sm: "row" }} align="start" justify="space-between">
            <LoginCta text="Remember your password?" />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}
